import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import CssBaseline from "@mui/material/CssBaseline";
import ConfirmationDialog from "./components/common/ConfirmationDialog";
import ThemeProvider from "./theme";
import { CollapseDrawerProvider } from "./contexts/CollapseDrawerContext";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <CssBaseline />
      <CollapseDrawerProvider>
        <ConfirmationDialog>
          <App />
        </ConfirmationDialog>
      </CollapseDrawerProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
