import React from "react";
import { useAuth } from "../contexts/AuthContext";
import ApplicationRoutes from "./application";
import AuthRoutes from "./auth";
function AppRoutes() {
  const { login } = useAuth();
  return (
    <>
      {login && Boolean(localStorage.getItem("token")) ? (
        <ApplicationRoutes />
      ) : (
        <AuthRoutes />
      )}
    </>
  );
}

export default AppRoutes;
