import React, { lazy, Suspense } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useRoutes,
} from "react-router-dom";
import DashboardLayout from "../layouts/dashboard";
import NoPageFound from "../pages/404";
import LoadingScreen from "../components/LoadingScreen";
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

function ApplicationRoutes() {
  return (
    <Routes>
      <Route exact path="/" element={<Coupons />} />
      <Route path="*" element={<NoPageFound />} />
    </Routes>
  );
  // return useRoutes([
  //   // Dashboard Routes
  //   {
  //     path: "dashboard",
  //     element: <DashboardLayout />,
  //     children: [
  //       { element: <Navigate to="/dashboard/coupons" replace />, index: true },
  //       { path: "coupons", element: <Coupons /> },
  //     ],
  //   },
  //   // {
  //   //   path: "dashboard",
  //   //   element: <Coupons />,
  //   // },

  //   { path: "*", element: <NoPageFound /> },
  // ]);
}

const Coupons = Loadable(lazy(() => import("../pages/dashboard/coupons")));

export default ApplicationRoutes;
