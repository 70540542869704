import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import NotistackProvider from "./components/NotistackProvider";
import { AuthContextProvider } from "./contexts/AuthContext";
import AppRoutes from "./routes";
function App() {
  return (
    <AuthContextProvider>
      <NotistackProvider>
        <Router>
          <AppRoutes />
        </Router>
      </NotistackProvider>
    </AuthContextProvider>
  );
}

export default App;
