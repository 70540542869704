// ----------------------------------------------------------------------

import Iconify from "../../../components/Iconify";

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "general",
    items: [
      {
        title: "Coupons",
        path: "/",
        icon: <Iconify icon="bxs:coupon" />,
      },
    ],
  },
];

export default navConfig;
