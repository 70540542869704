import ArrowBack from "@mui/icons-material/ArrowBack";

import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router";

function NoPageFound() {
  const classes = useStyle();

  console.log("inside 404 page");
  const navigate = useNavigate();

  return (
    <Box display="flex" justifyContent="center" height="100vh">
      <Helmet>
        <title>404</title>
        <meta charset="UTF-8" />
        <meta name="description" content="" />
        <meta name="referrer" content="origin" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <Box display="flex" justifyContent="center" flexDirection="column">
        <Typography variant="h2" align="center">
          404
        </Typography>
        <Typography variant="h6" align="center">
          Page not found
        </Typography>
        <Box display="flex" justifyContent="center">
          <ButtonBase onClick={() => navigate("/")}>
            <ArrowBack color="secondary" />
            &nbsp; Go Back
          </ButtonBase>
        </Box>
      </Box>
    </Box>
  );
}

const useStyle = makeStyles((theme) => ({
  backdrop: {
    backgroundColor: "#15293A",
    position: "relative",
  },
  backdropOverlay: {
    position: "absolute",
    top: 0,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    left: 0,
    padding: "20px 100px",
  },
  root: {
    backgroundColor: "#fff",
    height: "100%",
  },
  promoCode: {
    backgroundColor: theme.palette.secondary.light,
    padding: "8px 28px",
    marginTop: "316px",
  },
  payPalSelect: {
    width: "100%",
    border: "1px solid #A5B0BE",
    borderRadius: "4px",
    boxSizing: "border-box",
  },
  textBtn: {
    color: "#AAC9DD",
  },
}));

export default NoPageFound;
