import React from "react";
import {
  Route, Routes
} from "react-router-dom";
import NoPageFound from "../pages/404";
import Auth from "../pages/auth";
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
function AuthRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Auth />} />
    </Routes>
  );
}

export default AuthRoutes;
