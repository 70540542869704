import { createContext, useContext, useEffect, useState } from "react";
import { getJwt } from "../services/authServices";
const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthContextProvider = ({ children }) => {
  const [login, setLogin] = useState(false);
  const [userData, setUserData] = useState({});

  function loginUser() {
    setLogin(true);
  }


  function logoutUser() {
    setLogin(false);
    localStorage.removeItem("token");
    window.location.reload();
  }

  useEffect(async () => {
    const token = getJwt();
    if (token) {
      setLogin(true);
      let user = JSON.parse(localStorage.getItem("user"));
      setUserData(user);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        login,
        userData,
        loginUser,
        setUserData,
        logoutUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
