import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { createContext, useContext, useRef, useState } from "react";

const ConfirmationDialogContext = createContext({});

const ConfirmationDialog = (props) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const resolver = useRef();

  const handleShow = (t = "", s = "") => {
    setTitle(t);
    setSubtitle(s);
    setShowConfirmDialog(true);

    return new Promise(function (resolve) {
      resolver.current = resolve;
    });
  };

  const handleOk = () => {
    resolver.current && resolver.current(true);
    setShowConfirmDialog(false);
  };

  const handleCancel = () => {
    resolver.current && resolver.current(false);
    setShowConfirmDialog(false);
  };

  return (
    <ConfirmationDialogContext.Provider
      value={{ showConfirmDialog: handleShow }}
    >
      {props.children}
      <Dialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"xs"}
      >
        <DialogTitle id="alert-dialog-title">
          {title ? title : "Are you sure you want to delete?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {subtitle ? subtitle : ""}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Disagree
          </Button>
          <Button onClick={handleOk} autoFocus color="secondary">
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </ConfirmationDialogContext.Provider>
  );
};

export const useConfirmationDialogContext = () =>
  useContext(ConfirmationDialogContext);

export default ConfirmationDialog;
