// import logo from "./logo.svg";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router";
import SignIn from "../components/auth/SignIn";
// import ForgotPassword from "../components/auth/ForgotPassword";
// import SignUp from "../components/auth/SignUp";

function Auth() {
  const classes = useStyle();

  const navigate = useNavigate();

  // const [signupScreen, setSignupScreen] = useState(false);
  const [loginScreen, setLoginScreen] = useState(true);
  // const [forgotPassword, setForgotPassword] = useState(false);

  return (
    <div className={classes.Auth}>
      {/* <Container maxWidth="md"> */}
      <Helmet>
        <title>Studio DMI - Admin log in</title>
        <meta charset="UTF-8" />
        <meta name="description" content="" />
        <meta name="referrer" content="origin" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <Grid
        container
        spacing={0}
        justifyContent="center"
        // alignContent="center"
        // alignItems="center"
      >
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          xl={6}
          sx={{ display: { xs: "none", md: "block" } }}
        >
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            className={classes.backdrop}
          >
            <img
              src="/authImage.png"
              height="100vh"
              style={{ height: "100vh", width: "auto", objectFit: "cover" }}
            />
            <Box className={classes.backdropOverlay}>
              <img
                src="/logo1.png"
                width="auto"
                style={{
                  cursor: "pointer",
                  objectFit: "contain",
                }}
                onClick={() => navigate("/")}
              />
              &nbsp;&nbsp;
              <Typography variant="h4" color="textSecondary" align="center">
                Mixing and Mastering Studio Led By Luca Pretolesi
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          sx={{ height: { xs: "100vh" } }}
        >
          {/* {signupScreen && (
            <SignUp
              login={() => {
                setSignupScreen(false);
                setLoginScreen(true);
              }}
              forgotPass={() => {
                setSignupScreen(false);
                setForgotPassword(true);
              }}
            />
          )} */}
          <SignIn
            signup={() => {
              setLoginScreen(false);
              // setSignupScreen(true);
            }}
            forgotPass={() => {
              setLoginScreen(false);
              // setForgotPassword(true);
            }}
          />
          {/* {forgotPassword && (
            <ForgotPassword
              login={() => {
                setForgotPassword(false);
                setLoginScreen(true);
              }}
              signup={() => {
                setForgotPassword(false);
                setSignupScreen(true);
              }}
            />
          )} */}
        </Grid>
      </Grid>
      {/* </Container> */}
    </div>
  );
}

const useStyle = makeStyles((theme) => ({
  backdrop: {
    backgroundColor: "#15293A",
    position: "relative",
  },
  backdropOverlay: {
    position: "absolute",
    top: 0,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    left: 0,
    padding: "20px 100px",
  },
  Auth: {
    backgroundColor: "#fff",
    height: "100%",
  },
  promoCode: {
    backgroundColor: theme.palette.secondary.light,
    padding: "8px 28px",
    marginTop: "316px",
  },
  payPalSelect: {
    width: "100%",
    border: "1px solid #A5B0BE",
    borderRadius: "4px",
    boxSizing: "border-box",
  },
  textBtn: {
    color: "#AAC9DD",
  },
}));

export default Auth;
