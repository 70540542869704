import axios from "./../utils/axios";

export function getJwt() {
  return localStorage.getItem("token");
}
export function loginWithJwt(jwt) {
  localStorage.setItem("token", jwt);
}

export const login = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post("api/user/adminLogin", data)
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("user", JSON.stringify(response.data.user));
          axios.defaults.headers.common.Authorization =
            "bearer " + response.data.token;
          resolve(response.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
